<template>
  <div class="block-width">
    <div class="logo w-1200">
      <div class="logo-item" @click="handleBackHome">
        <div class="logo-item-image">
          <img src="../../assets/login/logo.gif" />
        </div>
        <!-- <svg class="logo-item-store-image" height="22">
          <text x="10" y="16"></text>
        </svg> -->
      </div>
      <div class="logo-search">
        <div class="logo-search-type">
          <div class="text">商品</div>
        </div>
        <div class="logo-search-input">
          <input v-model="query" placeholder="输入产品编号 产品名称 关键字" />
        </div>
        <button
          class="logo-search-button search-all"
          @click="handleSearchStore"
        >
          <svg class="button-icon" height="14">
            <text x="0" y="11">搜索全站</text>
          </svg>
        </button>
        <!-- <button class="logo-search-button search-store">
          <svg class="button-icon" width="78" height="14">
            <text x="0" y="11">元素周期表</text>
          </svg>
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    query: {
      get: function() {
        return this.params.q || '';
      },
      set: function(newValue) {
        this.params.q = newValue;
      },
    },
  },
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params.q = this.$route.query.q;
  },
  methods: {
    getParams() {
      return {
        ...this.params,
      };
    },
    handleBackHome() {
      this.$router.push({
        name: 'Home',
      });
    },
    handleSearchStore() {
      this.$router.tp({
        name: 'search',
        query: {
          ...this.getParams(),
        },
      });
      // this.$router.push({
      //   name: "replace",
      //   params:{
      //     routeName:"search",
      //   },
      //   query: {
      //     ...this.getParams()
      //   }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
.block-width {
  position: relative;
  height: 110px;
  width: 100%;
  background: #fff;

  .logo {
    height: 100%;
    margin: auto;
    position: relative;

    &-item {
      height: 60px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      margin: auto;
      display: inline-flex;

      &-store-image {
        width: calc(100% - 159px);
        margin: auto;

        > text {
          font-size: 20px;
          font-weight: 600;
          fill: #2659f2;
        }
      }

      &-image {
        width: 159px;
        height: 100%;
        position: relative;
        display: inline-block;
        margin-left: 33px;
        cursor: pointer;

        > img {
          width: 158px;
          height: 44px;
          display: inline-block;
          position: absolute;
          top: 0px;
          bottom: 0px;
          margin: auto;
        }
      }
    }

    &-search {
      position: absolute;
      width: 586px;
      height: 39px;
      // background: #000;
      top: 0px;
      bottom: 0px;
      right: 30px;
      margin: auto;
      display: flex;
      flex-direction: row;

      &-type {
        width: 95px;
        height: 100%;
        background: transparent url('../../assets/store/search-1.png') no-repeat
          100%;
        background-size: 100% 100%;

        .text {
          position: relative;
          width: 35px;
          height: 39px;
          line-height: 39px;
          padding-right: 16px;
          margin: auto;

          &:after {
            position: absolute;
            width: 12px;
            height: 8px;
            top: 0px;
            bottom: 0px;
            right: 0px;
            margin: auto;
            content: '';
            display: inline-block;
            background: transparent url('../../assets/store/search-1-text.png')
              no-repeat 100%;
            background-size: 100% 100%;
          }
        }
      }

      &-input {
        width: 317px;
        height: 100%;
        background: transparent url('../../assets/store/search-2.png') no-repeat
          100%;
        background-size: 101% 100%;

        > input {
          width: calc(100% - 40px);
          height: calc(100% - 14px);
          border: 0px;
          padding: 8px 20px;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
      }

      &-button {
        width: 87px;
        height: 100%;
        background: transparent;
        background-size: 100% 100%;
        border: 0px;
        position: relative;

        .button-icon {
          // width: 3.75em;
          width: 58px;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          margin: auto;

          > text {
            font-size: 14px;
            fill: #fff;
          }
        }

        &:focus {
          outline: none;
        }

        &.search-all {
          background: transparent url('../../assets/store/search-3.png')
            no-repeat 100%;
        }

        &.search-store {
          background: transparent url('../../assets/store/search-4.png')
            no-repeat 100%;
        }
      }
    }
  }
}
</style>
