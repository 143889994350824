<template>
  <div class="search">
    <header-user></header-user>
    <Search :searchInput="searchInput"/>
    <header-menu :prodCatId="prodCatId"></header-menu>
    <crumbs :data="crumbs"></crumbs>
    <search-param @search="searchSpu"></search-param>

    <div class="search-content" v-if="istrue">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <!--        <el-table-column-->
        <!--          prop="spuNum"-->
        <!--          label="产品编号"-->
        <!--          width="120px"-->
        <!--        ></el-table-column>-->
        <el-table-column label="产品名称">
          <template slot-scope="scope">
            <el-link
              @click.native="openSpuDetail(scope.row.storeId, scope.row.spuId)"
              target="_blank"
            >
              <span v-html="scope.row.title"></span>
            </el-link>
          </template>
        </el-table-column>
        <el-table-column
          label="标物编号"
          width="420px"
        >
        <template slot-scope="scope">
          <div style="white-space: nowrap;" :title="scope.row.bwNum" v-html="scope.row.bwNum"></div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="浓度范围" width="300px">
        <template slot-scope="scope">
          <div class="cell-concentrationRange" :title="scope.row.concentrationRange" v-html="scope.row.concentrationRange"></div>
        </template>
      </el-table-column> -->
        <!-- <el-table-column prop="expireDate" label="有效期"></el-table-column> -->
        <el-table-column prop="salePrice" label="价格" width="200px"></el-table-column>
        <!-- <el-table-column prop="goodDeadline" label="货期"></el-table-column> -->
        <el-table-column prop="sendPlace" label="发货地" width="200px"></el-table-column>
        <!-- <el-table-column label="产品品牌" width="200px">
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.brandName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" width="200px">
          <template slot-scope="scope">
            <el-link @click="StoreHome(scope.row.storeId)">{{
              scope.row.storeName
            }}</el-link>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openSpuDetail(scope.row.storeId, scope.row.spuId)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="pageSearch"
        :current-page="page.current"
        style="text-align: right;margin-top: 20px"
        background
        :total="page.total"
        :page-size="page.size"
        layout="prev, pager, next, jumper, ->, total"
      >
      </el-pagination>
    </div>
    <div class="recommend" v-else>
      <div class="title">抱歉，没有找到与搜索相关的商品</div>
      <div class="tuijian">推荐给您：</div>
      <div
        class="list"
        v-for="(i, index) in data"
        :key="index"
        @click="openSpuDetail(i.storeId, i.spuId)"
      >
        {{ i.name }}
      </div>
    </div>
    <!-- <baoyou></baoyou> -->
    <div class="kongbai"></div>
    <cart></cart>
    <foot></foot>
  </div>
</template>

<script>
import header_user from '@/components/header';
import header_menu from '@/components/home/menu';
import search_search from './search-search';
import search_crumbs from './search-crumbs';
import search_screen from './search-screen';
import searchParam from './search-param';
import baoyou from '@/components/order/baoyou';
import foot from '@/components/foot';
import cart from '@/components/cart';
import { searchSpu } from '@/api/search.js';
import { get, post } from '@/utils/request';
import { openSpuDetail, openStoreHome } from '@/utils/pageUtils.js';
import Search from '@/components/home/search.vue';
export default {
  data() {
    return {
      tableData: [],
      page: {
        total: 1,
        size: 10,
        current: 1,
      },
      loading: true,
      time: 300,
      prodCatId: '',
      data: [],
      istrue: true,
      searchInput:"",
    };
  },
  created() {
    this.searchSpu({});
    this.prodCatId = this.$route.query.prodCatId;
    this.searchInput = this.$route.query.q;
  },
  components: {
    'header-user': header_user,
    'header-menu': header_menu,
    'search-search': search_search,
    crumbs: search_crumbs,
    screen: search_screen,
    baoyou: baoyou,
    foot: foot,
    cart: cart,
    searchParam,
    Search,
  },
  computed: {
    crumbs: function() {
      var other = this.$route.query.other;
      return (other && other.split('-')) || '搜索';
    },
  },
  methods: {
    StoreHome(e) {
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e,
        },
      });
      window.open(href, '_blank');
    },
    openSpuDetail,
    openStoreHome,
    pageSearch(page) {
      this.page.current = page;
      this.searchSpu({ page });
    },
    searchSpu(params) {
      let query = this.$route.query;
      for (let key in query) {
        params[key] = query[key];
      }
      params.size = 10;
      params.page = this.page.current;
      this.loading = true;
      searchSpu(params)
        .then((res) => {         
          res.data.list.forEach((item)=>{
            if(item.concentrationRange){
              item.concentrationRange=item.concentrationRange.replace(
                'X10^(-6)',
                'X10<sup>-6</sup>'
              ).replaceAll(',','<br/>');  
            }
          });

          this.tableData = res.data.list;
          this.page.total = res.data.total;
          this.page.size = res.data.pageSize;
          if (res.data.list == '') {
            this.istrue = false;
            this.getlist();
          }else{

          }
          setTimeout(() => {
            this.loading = false;
          }, this.time);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getlist() {
      get('f/api/frontspu/getfootSpulist').then((res) => {
        this.data = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  background: #f5f5f5;
  .recommend {
    width: 1037px;
    background: #fff;
    margin: auto;
    padding: 40px 100px;
    .title {
      color: #ff6600;
    }

    .list {
      margin-top: 5px;
      font-size: 14px;
      color: #999;
      cursor: pointer;
    }
    .list :hover {
      color: #f66;
    }
    .tuijian {
      margin-top: 10px;
      font-size: 16px;
      color: #666;
    }
  }
  &-content {
    background: #fff;
    width: 1237px;
    padding: 10px 20px 20px 20px;
    margin: auto;
  }
}
  .cell-concentrationRange{
    white-space:nowrap;
  }
</style>
