<template>
  <div class="crumbs">
    <!-- <ul class="crumbs-content">
      <li class="crumbs-item">当前位置：</li>
      <li class="crumbs-item">首页</li>
      <li class="crumbs-item" v-for="(crumbs,index) in crumbsList" :key="index">{{crumbs}}</li>
    </ul> -->
  </div>
</template>

<script>
export default {
  computed: {
    crumbsList: function() {
      return Array.prototype.concat(this.data);
    },
  },
  props: {
    data: Array | String,
  },
};
</script>

<style lang="scss" scoped>
.crumbs {
  width: 100%;

  &-content {
    padding: 0px;
    height: 37px;
    /*no*/
    width: 1237px;
    line-height: 37px;
    /*no*/
    list-style: none;
    margin: auto;
  }

  &-item {
    display: inline-block;
    margin-left: 20px;
    margin-right: 10px;
    position: relative;

    &:first-of-type {
      margin-left: 0px;
      margin-right: 0px;
      &:before {
        content: '';
      }
    }

    &:first-of-type + li {
      margin-left: 0px;
      &:before {
        content: '';
      }
    }

    &:before {
      position: absolute;
      top: 0px;
      left: -20px;
      content: '>';
    }
  }
}
</style>
