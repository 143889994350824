import router from '@/router/index';

/**
 * 进入商品详情
 */
const openSpuDetail = function(storeId, spu, sku) {
  // router.push({ name: 'detail', params: { storeId, spu, sku } });
  // window.open(openSpuDetail, '_blank');

  const { href } = this.$router.resolve({
    name: 'detail',
    params: { storeId, spu, sku },
  });
  window.open(href, '_blank');
};

/**
 * 进入店铺主页
 * @param sid 店铺id
 */
const openStoreHome = function(sid) {
  router.push({ name: 'store_index', params: { sid } });
  // const { href } = this.$router.resolve({
  //   name: 'store_index',
  //   params: { sid },
  // });
  // window.open(href, '_blank');
};

/**
 * 进入确认订单页面
 */
const confirmOrder = function() {
  router.push({ name: 'settlement' });
   // const { href } = this.$router.resolve({
  //   name: 'settlement',
  // });
  // window.open(href, '_blank');
};

/**
 * 进入付款页面
 */
const openPay = function(params) {
  router.push({ name: 'Zhuanzhang', params });
};

export { openSpuDetail, openStoreHome, confirmOrder, openPay };
