<template>
  <div class="search-classify-item" v-if="is">
    <div class="search-classify-title">
      <span>{{name}}</span>
    </div>
    <div class="search-classify-content">
      <ul class="search-classify-content-button">
        <li class="search-classify-content-button-item" :class="{'active':active==0}" @click="handleChangeClick(0)">
          {{itemDefault}}
        </li>
        <li class="search-classify-content-button-item" :class="{'active':active==index+1}"
          v-for="(item,index) in itemList" @click="handleChangeClick(index+1)">{{item}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      is: function () {
        return this.data && this.itemDefault && this.name && this.itemList.length || false;
      },
      itemDefault: function () {
        return this.data.default;
      },
      itemList: function () {
        return this.data.data;
      },
      name: function () {
        return this.data.name || "";
      }
    },
    props: {
      data: Object,
      change: Function
    },
    data() {
      return {
        active: 0
      };
    },
    methods: {
      get() {
        if (this.active == 0) {
          return this.itemDefault;
        }
        return this.itemList[this.active - 1];
      },
      handleChangeClick(index) {
        this.active = index;
        this.change(this.get());
      }
    }

  }
</script>

<style lang="scss" scoped>
  .search-classify-item {
    width: 100%;
    // border-bottom: 1px solid #f4f4f4;
    display: inline-flex;
    flex-direction: row;
  }

  .search-classify-title {
    width: 90px;
    text-align: center;

    >span {
      display: inline-block;
      margin: 16px 0px;

      &:after {
        display: inline-block;
        content: ":";
        margin-left: 6px;
      }
    }
  }

  .search-classify-content {
    display: inline-block;
    flex: 100;

    &-button {
      list-style: none;
      padding: 0px;
      display: grid;
      justify-content: space-around;
      grid-template-columns: repeat(auto-fill, 100px);
      grid-gap: 10px 2px;

      &-item {
        display: inline-block;
        text-align: center;
        cursor: pointer;

        &:hover,
        &.active {
          color: #184fff;
        }

        position: relative;

        &:nth-of-type(11),
        &:first-of-type {
          &:before {
            content: "";
          }
        }

        &:before {
          position: absolute;
          left: -9px;
          color: #bababa;
          content: "|";
        }
      }
    }
  }
</style>