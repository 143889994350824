<template>
  <div class="search-param-wrapper">
    <div class="param-content">
      <ul class="param-list">
        <li class="item" v-for="(item,index) in conditionData" :key="index">
          <a :title="item.name" :class="{active:active==index}" @click="condition(index)" v-if="index<3">{{ item.name }}</a>
          <a :title="item.name" :class="{active:active==index}" @click="condition(index)" v-else>
            {{ item.name }}
            <div class="sort-wrapper">
              <i class="el-icon-caret-top"></i>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { searchSpu } from '@/api/search.js';
export default {
  name: "search-param",
  data() {
    return {
      active:0,
      conditionData:[
        {
          name:'综合',
          value:{}
        },
        {
          name:'销量',
          value:{
            orderSaleCount:1
          }
        },
        {
          name:'评论数',
          value:{
            orderCommentCount:1
          }
        }
        // 价格显示区间，去掉价格排序
        // ,
        // {
        //   name:'价格',
        //   value:{
        //     orderPrice:1
        //   }
        // }
      ]
    }
  },
  methods:{
    condition(index){
      this.active = index;
      this.$emit('search',this.conditionData[index].value)
    }

  }
}
</script>

<style scoped>
a {
  color: #606266;
  cursor: pointer;
}

.active {
  color: #409eff;
}

.param-list {
  padding: 0;
  background: #fff;
  margin: 0;
  display: flex;
  list-style: none;
}

.item {
  text-align: center;
  cursor: pointer;
}

.item > a::after {
  content: '';
  width: 1px;
  background: #dedede;
  position: absolute;
  right: 0px;
  top: 8px;
  bottom: 8px;
}

.item:last-child > a::after {
  width: 0;
}

.item > a {
  padding: 0px 20px;
  height: 32px;
  line-height: 32px;
  display: block;
  position: relative;
  font-size: 14px;
  text-decoration: none;
}

.price-wrapper {
  display: flex;
  flex-direction: row;
}

.sort-wrapper {
  display: flex;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: column;
  cursor: pointer;
}

.sort-wrapper > i {
  line-height: .6;
  color: #606266;
  font-size: 13px;
}
</style>

<style lang="scss" scoped>
.search-param-wrapper {
  width: 100%;
  background: #f5f5f5;

  .param-content {
    background: #fff;
    width: 1237px;
    padding: 10px 20px;
    margin: 10px auto;
  }
}
</style>
