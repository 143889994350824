import {get} from '@/utils/request';


export function getScreen() {
  return DraftPromiseData([{
    name: "筛选条件",
    "default": "不限",
    data: [
      "降价", "满减", "优惠券"
    ]
  }]);
}




export function searchSpu(params) {
 return get('f/api/frontspu',{params}).then(res=>{
   return res.data;
 }).catch(err=>err)
}
